import React from 'react';
import Signup from './authentication/Signup';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './authentication/Login';
import PrivateRoute from './authentication/PrivateRoute';
import ForgotPassword from './authentication/ForgotPassword';
import ResetPassword from './authentication/ResetPassword';
import Main from './Main';
import Navbar from './Navbar';

function App() {

    return (
        <div className="bg-secondary">
            <Router>
                <AuthProvider>
                    <Navbar className="m-2" />
                    <Switch>
                        <PrivateRoute exact path="/" component={Main} />
                        <Route path="/signup" component={Signup} />
                        <Route path="/login" component={Login} />
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Route path="/reset-password/:resetToken" component={ResetPassword} />
                    </Switch>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
