import React, { useContext, useState, useEffect } from 'react';

const AuthContext = React.createContext();

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => { 
        async function checkAuth() {
            if (!currentUser) {
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const response = await fetch(`${API_URL}/auth`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        if (response.status === 401) {
                            localStorage.removeItem('token');
                        } else {
                            setCurrentUser(token);
                        }
                        setLoading(false);
                        return true;
                    } catch (e) {
                        localStorage.removeItem('token');
                        setLoading(false);
                        return false;
                    }
                }
                else {
                    setLoading(false);
                }
            }
        }
        checkAuth();
    }, [currentUser])

    async function signup(email, password) {
        try {
            const data = JSON.stringify({ email, password });
            const response = await fetch(`${API_URL}/auth/signup`, {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 401) {
                return false;
            }
            await login(email, password);
        } catch (e) {
            console.log('signup error!', e);
            return false;
        }
    }

    async function login(email, password) {
        try {
            const data = JSON.stringify({ email, password });
            const response = await fetch(`${API_URL}/auth/login`, {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 401) {
                return false;
            }
            const token = await response.json();
            setCurrentUser(token.token);
            localStorage.setItem('token', token.token);
            return true;
        } catch (e) {
            console.log('login error!', e);
            return false;
        }
    }

    function logout() {
        localStorage.removeItem('token');
        setCurrentUser(null);
    }

    async function resetPassword(token, password) {
        const data = JSON.stringify({ token, password });
        const response = await fetch(`${API_URL}/auth/resetpassword`, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const json = await response.json();
        return json;
    }

    async function forgotPassword(email) {
        const data = JSON.stringify({ email });
        const response = await fetch(`${API_URL}/auth/forgotpassword`, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const json = await response.json();
        return json;
    }

    function getAuthHeader() {
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser}`,
        };
    }

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        forgotPassword,
        getAuthHeader
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
