import { Container, InputGroup, FormControl, Form } from 'react-bootstrap';
import Display from './Display';
import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from './contexts/AuthContext';

export default function Main() {
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
    const [displayText, setDisplayText] = useState('');
    const [loading, setLoading] = useState(false);
    const { getAuthHeader } = useAuth();

    useEffect(() => {
        const getInitialData = async function() {
            const response = await fetch(`${API_URL}/game/current`, {
                headers: getAuthHeader(),
            });
            const json = await response.json();
            setDisplayText(json.current);
        }
        getInitialData();
    }, [API_URL, getAuthHeader])

    const inputCommand = useRef();

    const enterCommand = async function (evt) {
        evt.preventDefault();
        if(loading) {
            return;
        }
        const enteredValue = `${inputCommand.current.value}`;
        inputCommand.current.value = '';
        setDisplayText(`\n\n> ${enteredValue}`);
        setLoading(true);
        const inputResponse = await performAction(enteredValue);
        setLoading(false);
        setDisplayText(`\n${inputResponse}`);
    };
    
    const performAction = async function(command) {
        const response = await fetch(`${API_URL}/game/command`, {
            method: 'POST',
            body: JSON.stringify({ command: command }),
            headers: getAuthHeader(),
        });
        if (response.status === 401) {
            console.log('unauthorized');
            return false;
        }
        const json = await response.json();
        return json.response;
    }

    return (
        <>
            <div className="bg-secondary">
                <Container className="d-flex align-items-top justify-content-center mt-5"
                    style={{ minHeight: "100vh" }}>
                    <div className="w-100" style={{ maxWidth: "1000px" }}>
                        <Display displayText={displayText} setDisplayText={setDisplayText} />
                        <Form onSubmit={enterCommand}>
                            <InputGroup className="mb-3">
                                <FormControl className="bg-dark text-light" ref={inputCommand} placeholder="What do you want to do?" />
                            </InputGroup>
                        </Form>
                    </div>
                </Container>
            </div>
        </>
    )
}
