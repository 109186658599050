import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

export default function NavbarComponent() {
    const { logout, currentUser } = useAuth();

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="xxl">
                <Navbar.Brand as={Link} to="/">
                    Amnesio
                </Navbar.Brand>
                <Nav>
                    { currentUser && <Nav.Link onClick={e => logout()}>Log Out</Nav.Link> }
                </Nav>
            </Navbar>
        </>
    )
}
