import React, { useRef, useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useParams } from 'react-router-dom';
import CenteredContainer from './CenteredContainer';

export default function ResetPassword() {
    const { resetToken } = useParams();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { resetPassword } = useAuth();

    async function handleSubmit(e) {
        e.preventDefault();

        if(passwordRef.current.value !== confirmPasswordRef.current.value) {
            return setError('Passwords to not match.');
        }

        try {
            setError('');
            setLoading(true);
            await resetPassword(resetToken, passwordRef.current.value);
            history.push("/login");
        }
        catch(e) {
            setError('Failed to reset password');
        }

        setLoading(false);

    }

    return (
        <CenteredContainer>
            <Card bg="dark" variant="dark">
                <Card.Body>
                    <h2 className="text-center mb-4 text-white">Sign Up</h2>
                    { error && <Alert variant="danger">{error}</Alert> }
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="password">
                            <Form.Label className="text-white">Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="confirm-password">
                            <Form.Label className="text-white">Confirm Password</Form.Label>
                            <Form.Control type="password" ref={confirmPasswordRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type="submit">
                            Sign Up
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </CenteredContainer>
    )
}
