import React, {useEffect, useState, useRef} from 'react'
import { Form } from 'react-bootstrap';

export default function Display({displayText, setDisplayText}) {
    const [textAreaText, setTextAreaText] = useState('');
    const textLog = useRef();

    useEffect(() => {
        setTextAreaText(t => t + `${displayText}`);
        textLog.current.scrollTop = textLog.current.scrollHeight;
        if(displayText) {
            setDisplayText('');
        }
    }, [displayText, setDisplayText]);

    return (
        <>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control className="bg-dark text-light" as="textarea" rows={15} readOnly="readonly" value={textAreaText} ref={textLog}/>
            </Form.Group>
        </>
    )
}
